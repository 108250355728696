<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 26.03.2021 09:58:31 UTC
          </div>
          <div id='tip'>

            <img src="@/assets/cryptoboard/OMI-H1Gd40A.png" />
          </div>
      </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 14.03.2021 11:27:13 UTC
          </div>
          <div id='tip'>
            his is what I said for OMI: please always refer to what I wrote for the GEM, because otherwise you make mistakes, and it's so simple:

            "The first target will be 5x this price. And only a small chunk. The main target is 10x, and the third target is 25x."

            I see one guy in chat says "first target is where you must sell the biggest amount"

            I never said that. I said only the first target is where you must 1) book profit and 2) important: ensure you can't lose - it must become a free trade by adjusting stop at entry for the remainder.

            Here in OMI case I actually wrote you everything clearly: 5x SMALL chunk, and bam, small chunk it is 10-20%... heck if you take 20% it becomes a FREE TRADE already. The rest at main target and rest taking a stab at 25x - which is not easy for this big marketcap though. Although I see OMI as a leader in NFT so actually possible.

            Very proud of this Blockchain Whispers GEM.

            Cheers!
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OMI',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
